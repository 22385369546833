@import "../../../style/variables";

.wl-info-cards-component {
    .card {
        max-width: 25%;
        min-width: 300px !important;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    @media (max-width: map-get($grid-breakpoints, "lg")) {
        .card {
            max-width: 100%;
        }
    }
}
