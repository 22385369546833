@import "../../../style/variables";

.lo-footer-component {
    ul {
        list-style-type: none !important;
        padding: 0;

        li {
            font-size: 00.825rem;
            margin-top: 0.5rem;
            margin-bottom: 0.25rem;

            a {
                text-decoration: none;
                color: $black;

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}
