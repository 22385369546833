@import "../../style/variables";

.ah-ats-help-component {
    .hero {
        background-image: url("../../assets/images/hero-4.jpg");
        background-size: cover;
        color: black;
        height: 400px;
        padding-top: 1px;

        .hero-text {
            background-color: rgba($white, 0.88);
            border: 2px solid rgba($white, 0.95);
            margin-top: 100px;
        }

        @media (max-width: map-get($grid-breakpoints, md)) {
            height: 350px;

            .hero-text {
                display: inline-block;
                margin-top: 60px;
                text-align: center;
                width: 100%;
            }
        }
    }

    img {
        max-height: 10rem;
    }
}
