@import "../../../style/variables";

.lo-navigation-component {
    .navbar {
        border-bottom: 1px solid rgba(200, 200, 200, 0.50);

        .navbar-brand {
            img {
                max-height: 48px;

                @media (max-width: map-get($grid-breakpoints, sm)) {
                    max-height: 36px;
                }
            }
        }

        a {
            &.nav-link {
                color: $text-muted;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }

            &.btn {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }
}
