.fm-form-control {
    position: relative;

    .validation {
        position: absolute;
        right: 8px;
        top: 7px;
        z-index: 5;
    }
}
