@import "../../../style/variables";

.wl-hero-component {
    .hero {
        background-image: url("../../../assets/images/hero-1.jpg");
        background-size: cover;
        color: white;
        height: 500px;

        .hero-text {
            background-color: rgba($dark, 0.88);
            border: 2px solid rgba($dark, 0.95);
            margin-top: 150px;

            .btn-hero {
                font-size: 2rem;
            }

            strong {
                text-shadow: -1px 1px 0 $black,
                              1px 1px 0 $black,
                             1px -1px 0 $black,
                            -1px -1px 0 $black;
            }
        }

        @media (max-width: map-get($grid-breakpoints, md)) {
            height: 350px;

            .hero-text {
                display: inline-block;
                margin-top: 60px;
                text-align: center;
                width: 100%;
            }
        }
    }
}
