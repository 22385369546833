@import "../../node_modules/bootstrap/scss/functions";

/// Custom Variables


/// Bootstrap Overrides
$blue: #0080ff;
//$secondary: #ffa500;
//$light: #4ca6ff;
$dark: #004c99;
$enable-negative-margins: true;

$border-radius: 0.15rem !default;
$border-radius-sm: 0.1rem !default;
$border-radius-lg: 0.25rem !default;

@import "../../node_modules/bootstrap/scss/variables";
