@import "../../style/variables";

.htrw-how-to-write-a-resume-component {
    .hero {
        background-image: url("../../assets/images/hero-3.jpg");
        background-size: cover;
        color: white;
        height: 400px;
        padding-top: 1px;

        .hero-text {
            background-color: rgba($dark, 0.88);
            border: 2px solid rgba($dark, 0.95);
            margin-top: 100px;
        }

        @media (max-width: map-get($grid-breakpoints, md)) {
            height: 350px;

            .hero-text {
                display: inline-block;
                margin-top: 60px;
                text-align: center;
                width: 100%;
            }
        }
    }

    img {
        max-height: 10rem;
    }

    .table-of-contents {
        ul {
            list-style: none;
            
            li {
                display: inline-block;
                margin: 0 .5rem;
            }
        }
    }
}
