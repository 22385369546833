@import "../../style/variables";

.he-hire-an-expert-component {
    height: 100%;
    position: relative;

    .hero {
        background-image: url("../../assets/images/hero-2.jpg");
        background-size: cover;
        height: 500px;
        padding-top: 100px;

        .bg-white-trans {
            background-color: rgba(255, 255, 255, 0.3);
            border: 1px solid rgba(255, 255, 255, 0.6);
        }

        @media (max-width: map-get($grid-breakpoints, md)) {

            .hero-text {
                padding-right: 0;
                text-align: center;
                width: 100%;
            }
        }

        @media (max-width: map-get($grid-breakpoints, lg)) {
            height: 350px;
            padding-top: 65px;
            
            .bg-white-trans {
                background-color: rgba(255, 255, 255, 0.88);
                border: 1px solid rgba(200, 200, 200, 0.95);
            }
        }
    }

    .step-number {
        $fs: 5rem;
        $sz: 6.25rem;

        @media (max-width: map-get($grid-breakpoints, md)) {
            $fs-sm: 2rem;
            $sz-sm: 3.125rem;

            font-size: $fs-sm;
            line-height: $fs-sm;
            min-width: $sz-sm;
            min-height: $sz-sm;
        }

        border: .5rem double $primary;
        border-radius: 150px;
        border-style: outset;
        display: inline-block;
        font-size: $fs;
        line-height: $fs;
        min-width: $sz;
        min-height: $sz;
        text-align: center;
        vertical-align: middle;
    }
}
