@import "../../../style/variables";

.wl-resume-stats-component {
    h5 {
        display: block;
        width: 80%;

        @media (min-width: map-get($grid-breakpoints, md)) {
            display: inline-block;
            width: 25%;
        }
    }
}
