@import "../../../style/variables";

.rb-progress-component {
    svg {
        display: inline-block;
        text-align: center;
    }

    .bar {
        margin: 1rem auto;
        width: 5px;
        height: 17px;
        
        &.bar-success {
            background-color: $green;
        }

        &.bar-gray {
            background-color: $gray-600;
        }

        @media (max-width: map-get($grid-breakpoints, lg)) {
            display: inline-block;
            height: 5px;
            margin-left: 1rem;
            margin-right: 1rem;
            width: 15px;
        }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
        svg {
            max-width: 1.5rem;
        }

        .btn {
            font-size: .75rem !important;
            padding: 3px !important;
            background-color: pink !important;
        }
    }
}
