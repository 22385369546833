@import "../../style/variables";

.rb-builder-component {
    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
        .builder-step {
            min-height: 460px;
            margin-bottom: 2rem;
        }
    }
}
