@import "../../../style/variables";

.wl-getting-started-component {
    .step-number {
        $fs: 5rem;
        $sz: 6.25rem;

        @media (max-width: map-get($grid-breakpoints, md)) {
            $fs-sm: 2rem;
            $sz-sm: 3.125rem;

            font-size: $fs-sm;
            line-height: $fs-sm;
            min-width: $sz-sm;
            min-height: $sz-sm;
        }

        border: .5rem double $primary;
        border-radius: 150px;
        border-style: outset;
        display: inline-block;
        font-size: $fs;
        line-height: $fs;
        min-width: $sz;
        min-height: $sz;
        text-align: center;
        vertical-align: middle;
    }
}
